import { render, staticRenderFns } from "./GetStarted.vue?vue&type=template&id=7fb3a30e&scoped=true&"
import script from "./GetStarted.vue?vue&type=script&lang=js&"
export * from "./GetStarted.vue?vue&type=script&lang=js&"
import style0 from "./GetStarted.vue?vue&type=style&index=0&id=7fb3a30e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb3a30e",
  null
  
)

export default component.exports