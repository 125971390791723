export const passwordGenerator = () => {
  const pwdChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const pwdLen = 10
  const randPassword = new Array(pwdLen)
    .fill(0)
    .map(x =>
      (function (chars) {
        const umax = Math.pow(2, 32)
        const r = new Uint32Array(1)
        const max = umax - (umax % chars.length)
        do {
          crypto.getRandomValues(r)
        } while (r[0] > max)
        return chars[r[0] % chars.length]
      })(pwdChars)
    )
    .join('') + 'Aa0'

  return randPassword
}
