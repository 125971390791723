<template>
  <div class="company-search-container" :class="{ 'is-list-open': isListOpen }">
    <label class="field-label">{{ searchLabel }}</label>
    <ValidationProvider
      v-if="!noCompanySearch"
      name="company"
      class="search-box"
      data-testid="company-search"
      vid="company"
      tag="div"
      :rules="!isCantFindCompany ? 'no_leading_space|required' : ''"
      :custom-messages="messageGenerator(rules, errorMessages)"
      #default="{ errors }"
    >
      <i class="icon material-icons" :class="{ disabled: isCantFindCompany }" v-if="!freeTextCompany && !loading">search</i>

      <div class="field-container" @click="handleSeeking">
        <vue-instant
          name="company-name"
          placeholder="Company name"
          ref="typeAhead"
          rules="no_leading_space|required"
          v-model="typeahead.value"
          :class="{ 'is-list-open': isListOpen, error: errors.length }"
          :suggestion-attribute="typeahead.suggestionAttribute"
          :show-autocomplete="true"
          :autofocus="false"
          :findSimilar="false"
          :allowSimilar="true"
          :suggestions="typeahead.suggestions"
          :suggestOnAllWords="false"
          :disabled="isCantFindCompany"
          @selected="selectCompany"
          @enter="handleOnEnter"
          v-click-outside="closeList"
        ></vue-instant>
      </div>

      <FieldError v-if="!isCantFindCompany" v-bind="{ errors }" />
      <div class="checkbox-container" :class="{ 'checkbox-checked': isCantFindCompany }">
        <Checkbox fieldId="isCantFindCompany" name="isCantFindCompany" :label="cantFindCompanyLabel" :value="isCantFindCompany" @input="clearCompany" />
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import messageGenerator from '@/config/vee-validate.message-generators'
import { findCompanies, getCompanyEntityTypeCode } from '@/api/company'
import { countriesOptions, countryNameFromCode } from '@/utils/countries'
import FieldError from '@/components/forms/FieldError'
import Checkbox from '@/components/dynamicForms/Checkbox'

export default {
  name: 'FindCompany',
  components: {
    ValidationProvider,
    FieldError,
    Checkbox
  },
  props: {
    freeTextCompany: {
      type: Boolean,
      default: false
    },
    noCompanySearch: {
      type: Boolean,
      default: false
    },
    hasUserCompanyAttached: {
      type: Boolean,
      default: false
    },
    isSoleTraderModalOpen: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [String, Number]
    }
  },
  data() {
    return {
      messageGenerator,
      companyName: null,
      company: null,
      typeahead: {
        value: '',
        suggestionAttribute: 'title',
        suggestions: this.getSuggestions
      },
      countriesOptions,
      isSoleTrader: false,
      isCantFindCompany: false,
      companyNumber: null,
      countryOfResidence: this.$config.whitelabel.country.defaultCountry,
      defaultCountryCode2: this.$config.whitelabel.country.defaultCountryCode2,
      postcode: null,
      loading: false,
      isListOpen: false,
      rules: { required: true },
      errorMessages: {
        required: "Please search for your company's name"
      }
    }
  },
  computed: {
    displayPostcode() {
      return this.isCantFindCompany === true && this.isSoleTrader === true && this.countryOfResidence === 'United Kingdom' && this.isSignUpRoute
    },
    isSignUpRoute() {
      return this.$route.name.includes('sign-up')
    },
    companyDetails() {
      return `${this.companyName}|${this.company}|${this.typeahead ? this.typeahead.value : ''}|${this.isSoleTrader}|${this.isCantFindCompany}|${this.companyNumber}|${
        this.countryOfResidence
      }|${this.postcode}`
    },
    searchLabel() {
      if (this.freeTextCompany || this.noCompanySearch) return this.$dictionary.companyNameLabel

      return "Search for your company's name"
    },
    companyNumberLabel() {
      if (this.hasUserCompanyAttached) return `What is your client's ${this.$dictionary.companyNumber}?`

      return `What is your ${this.$dictionary.companyNumber}?`
    },
    cantFindCompanyLabel() {
      return `I can't find my ${this.hasUserCompanyAttached ? "client's " : ''}company`
    }
  },
  methods: {
    handleValueChange(emmitedInput, field) {
      this.typeahead[field] = emmitedInput.value
    },
    toggleIsSoleTraderModal(cancelled) {
      if (cancelled) this.$emit('isSoleTraderConfirmed', false)
      else this.$emit('isSoleTraderConfirmed', true)

      if (this.isSoleTraderModalOpen) {
        this.triggerModal(!this.isSoleTraderModalOpen)
        this.$emit('isSoleTraderModalOpen', false)
      } else {
        this.triggerModal(!this.isSoleTraderModalOpen)
        this.$emit('isSoleTraderModalOpen', true)
      }
    },
    triggerModal(active) {
      if (active) {
        this.$modal.show('soleTraderConfirmationModal')
        this.$emit('isSoleTraderModalOpen', true)
      } else {
        this.$modal.hide('soleTraderConfirmationModal')
        this.$emit('isSoleTraderModalOpen', false)
      }
    },
    getCountry() {
      if (this.isCantFindCompany) return this.countryOfResidence

      if (this.company?.registeredAddress?.country) return this.company.registeredAddress.country

      if (this.company?.countryCode) return countryNameFromCode(this.company.countryCode)

      return this.$config.whitelabel.country.defaultCountry
    },
    async emitCompanyDetails() {
      const companyDetails = {
        companyName: this.typeahead.value,
        registrationNumber: this.company ? this.company.companyRegistrationNumber : this.companyNumber,
        country: this.getCountry(),
        postcode: this.postcode,
        isCantFindCompany: this.isCantFindCompany,
        sourceType: this.getSourceType()
      }

      if (this.$config.whitelabel.components.companySearch.soleTraderConfirmation && this.company && this.company.companyRegistrationNumber) {
        const response = await getCompanyEntityTypeCode(this.company.companyRegistrationNumber)
        this.$emit('input', {
          ...companyDetails,
          entityTypeCode: response.data ? response.data.entityTypeCode : null
        })
      } else {
        this.$emit('input', companyDetails)
      }
    },
    async emitWithEntityTypeCode() {
      if (this.company && this.company.companyRegistrationNumber) {
        const response = await getCompanyEntityTypeCode(this.company.companyRegistrationNumber)

        this.company = {
          entityTypeCode: response.data ? response.data.entityTypeCode : null
        }
      }
    },
    getSourceType() {
      if (this.company) return 'FromRegistrar'

      if (this.isSoleTrader) return 'SoleTrader'

      return 'Entered'
    },
    countyrCode(c) {
      return c.countryCode ? `(${c.countryCode})` : ''
    },
    companyRegistration(c) {
      return c.companyRegistrationNumber ? `(ABN: ${c.companyRegistrationNumber})` : ''
    },
    companyRegistrationNumber(c) {
      return c.companyRegistrationNumber ? `(${c.companyRegistrationNumber})` : ''
    },
    getSuggestions(value, callback) {
      this.company = null

      if (value.length < 3) return

      this.loading = true

      const requestData = {
        companyName: value,
        skip: 0,
        take: 0
      }

      const companiesPromise = findCompanies(requestData)
        .then(response =>
          response.data.companies
            .sort((a, b) => (a.name.toLowerCase().indexOf(value.toLowerCase()) > b.name.toLowerCase().indexOf(value.toLowerCase()) ? -1 : 1))
            .map(c => {
              if (this.$config.whitelabel.components.companySearch.countryCode) return { ...c, title: `${c.name} ${this.countyrCode(c)} ${this.companyRegistrationNumber(c)}` }
              else return { ...c, title: `${c.name} ${this.companyRegistration(c)}` }
            })
        )
        .catch(e => e)
        .finally(() => {
          this.loading = false
          this.isListOpen = true
        })
      callback(companiesPromise)
    },
    selectCompany(company) {
      this.company = company
      this.isListOpen = false
    },
    handleOnEnter(ev) {
      ev && ev.preventDefault()
    },
    handleSeeking() {
      const list = document.getElementsByClassName('sbx-searchbox__suggestions')

      if (list.length) return (this.isListOpen = true)

      return (this.isListOpen = false)
    },
    closeList() {
      this.isListOpen = false
    },
    clearCompany() {
      this.isCantFindCompany = !this.isCantFindCompany
      this.$emit('input', { companyName: this.companyName })
    }
  },
  watch: {
    isCantFindCompany() {
      this.typeahead = {
        value: '',
        suggestionAttribute: 'title',
        suggestions: this.getSuggestions
      }
    },
    displayPostcode(displayPostcode) {
      if (!displayPostcode) this.postcode = null
    },
    companyDetails() {
      this.emitCompanyDetails()
    },
    isSoleTraderModalOpen(isSoleTraderModalOpen) {
      this.triggerModal(isSoleTraderModalOpen)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_mixins.scss';
@import '@/assets/styles/swoop/_variables';

.company-search-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 2;
  padding: 0 50px;

  &.is-list-open {
    z-index: 4;
  }

  .field-label {
    width: 86%;
    padding: 16px 0px 14px 0px;
    display: flex;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.4px;
    margin-bottom: 14px;
    color: var(--color-primary-500);
  }

  .search-box {
    @include flex-display(column, flex-start, flex-start);
    justify-self: flex-start;
    width: 85%;
    position: relative;
    z-index: 4;
    padding-right: 40px;

    .icon {
      position: absolute;
      top: 16px;
      right: 55px;
      font-size: 18px;
      color: var(--color-primary-500);
      z-index: 1;

      &.disabled {
        color: var(--color-neutral-200);
      }
    }

    .checkbox-container {
      z-index: 1;
      margin-top: 16px;
    }

    .checkbox-checked {
      margin-top: 10px;
    }

    .field-container {
      position: relative;
      width: 100%;
      z-index: 4;

      .text-danger {
        top: 0;
        color: var(--color-error-500);
      }

      /deep/ {
        .input[type='text'],
        .sbx-searchbox__input,
        .sbx-searchbox__input-placeholder {
          width: 100%;
          height: 48px;
          border: 1px solid var(--color-primary-200);
          border-radius: 12px;
          box-sizing: border-box;
          background: $color-white;
          color: var(--color-primary-500);

          &:focus {
            border: 1px solid var(--color-primary-300);
            box-shadow: none;
          }

          &:disabled {
            background-color: var(--color-neutral-50) !important;
            border: 1px solid var(--color-neutral-100);
            color: var(--color-neutral-200) !important;
            cursor: not-allowed;
          }
        }

        .sbx-searchbox.is-list-open {
          .sbx-searchbox__input-placeholder,
          .sbx-searchbox__input {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        .sbx-searchbox__input-placeholder {
          line-height: 19px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  .sbx-searchbox {
    width: 100%;

    /deep/ {
      input {
        width: 100%;
      }
    }

    /deep/.sbx-searchbox__spinner {
      right: 18px;
    }

    /deep/.sbx-searchbox__suggestions {
      top: 105%;
      padding: 0;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      border: 1px solid #c9d2d8;
      box-shadow: none;

      li {
        min-height: 40px;
        display: block;
        position: relative;
        padding: 12px;
        font-size: 16px;
        line-height: 16px;
        text-decoration: none;
        text-transform: none;
        background-color: $color-white;
        color: var(--color-primary-500);
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: var(--color-secondary-500);
          color: $color-white;
        }
      }
    }

    &.error {
      /deep/input {
        border: 1px solid var(--color-error-500);

        &:focus {
          border: 1px solid var(--color-error-500);
        }
      }
    }
  }

  /deep/.sole-trader-buttons-container {
    @include flex-display(row, flex-start, flex-start);

    gap: 8px;

    .custom-button:first-child {
      border: 1px solid $color-primary;
      background-color: $color-white;

      .button-text {
        color: $color-primary;
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  .company-search-container {
    padding: 20px;
    flex-direction: column;
    width: 100%;
    .search-box {
      width: 100%;
      padding-right: 36px;
    }
  }
  .field-label {
    margin: 0 0;
    height: 20px;
  }
}
</style>
