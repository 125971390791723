<template>
  <div class="page-container">
    <StepCounter v-if="onboardingConfig.steps" :steps="onboardingConfig.steps" />
    <LoginModal v-if="showLoginModal" :closeModal="toggleLoginModal" />
    <GetStartedForm @form-submit="formValues => handleFormSubmit(formValues)" :isSubmitting="isSubmitting" :loadingPercentage="loadingPercentage" />
  </div>
</template>

<script>
import { stepCounterGetters, SET_STEP_COUNTER_LIST, SET_ACTIVE_STEP_INDEX, RESET_STEP_COUNTER_STATE } from '../../../../store/modules/goalsStepCounter/routines'
import { authGetters, loginRoutine, registerRoutine } from '@/store/modules/auth/routines'
import { saveGetStartedFormData } from '@/store/modules/onboarding/routines'
import GetStartedForm from '../../components/GetStartedForm.vue'
import { formatDynamicFormPayload } from '../../formatPayloads'
import { passwordGenerator } from '@/utils/passwordGenerator'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import LoginModal from '../../components/LoginModal.vue'
import StepCounter from '@/components/StepCounter.vue'
import deepLinking from '@/mixins/deepLinking'
import { mapActions, mapGetters } from 'vuex'
import onboardingConfig from '../../config'
import { saveForm } from '@/api/goals'
import { register } from '@/api/user'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'get-started',
  mixins: [amplitudeTracking, deepLinking],
  components: { GetStartedForm, StepCounter, LoginModal },
  data() {
    return {
      isSubmitting: false,
      loadingPercentage: 0,
      showLoginModal: false
    }
  },
  async mounted() {
    this.setActiveIndex(0)
    this.trackAmplitudeEventPageview()
    this.checkForDeepLinkShift()
  },
  computed: {
    ...mapGetters({
      stepCounterList: stepCounterGetters.STEP_COUNTER_LIST,
      activeStepIndex: stepCounterGetters.ACTIVE_STEP_INDEX,
      user: authGetters.USER
    }),
    onboardingConfig() {
      return onboardingConfig
    }
  },
  methods: {
    ...mapActions({
      setStepCounterList: SET_STEP_COUNTER_LIST,
      setActiveIndex: SET_ACTIVE_STEP_INDEX,
      resetStepCounterState: RESET_STEP_COUNTER_STATE,
      saveGetStartedFormData: saveGetStartedFormData.TRIGGER,
      login: loginRoutine.TRIGGER,
      register: registerRoutine.TRIGGER
    }),
    trackAmplitudeEventPageview() {
      this.$ma.trackEvent(amplitudeEvents.pageViews.GET_STARTED)
      this.trackPageViewWithUtmParams('Get Started')
    },
    toggleLoginModal() {
      this.showLoginModal = !this.showLoginModal
    },
    async saveFormInfo(companyId, formData) {
      const dynamicFormsPayload = formatDynamicFormPayload(formData.dynamic.fields, formData.dynamic.fieldIds, formData.dynamic.sectionId, formData.skipDetailsPage)
      await saveForm(companyId, formData.dynamic.formId, dynamicFormsPayload)
    },
    async handleFormSubmit(formData) {
      this.isSubmitting = true
      this.loadingPercentage = 20
      this.utm = this.filterParameters({ ...this.getUtmParameters(), ...this.getURLParams() })
      try {
        let companyId = this.user?.companies[0]?.companyId
        if (formData.registrationData) {
          const password = formData.registrationData?.password || passwordGenerator()
          const registrationData = {
            ...formData.registrationData,
            password,
            utm: Object.keys(this.utm).length ? this.utm : null
          }
          try {
            const { data } = await register(registrationData)
            companyId = data.companyId
          } catch (error) {
            // restricts the login modal to only show on failed registration
            this.showLoginModal = true
            throw new Error(error)
          }
          this.loadingPercentage = 80
          await this.login({
            userName: formData.registrationData.email,
            password: password
          })
          this.trackSignUpEvents(formData.registrationData.email)
          this.loadingPercentage = 100
        }
        this.saveGetStartedFormData(formData) // local storage
        this.saveFormInfo(companyId, formData)
        this.$router.push({ name: 'details-page' })
      } catch (error) {
        this.isSubmitting = false
        this.loadingPercentage = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.page-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 24px;
    width: 100%;
    background-color: yellow;
  }
}
</style>
