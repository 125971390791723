<template>
  <div class="wrapper">
    <header class="onboarding-header">
      <span>Already have an account?</span>
      <router-link :to="{ name: 'login' }" tag="button">
        Sign in
      </router-link>
    </header>
    <div class="content">
      <div class="text-container">
        <h3>
          {{ title }}
        </h3>
        <div class="description-container">
          <span v-for="(text, index) of descriptions" :key="text">
            <p :class="{ 'inline-para': index === descriptions.length - 1 }">
              {{ text }}
            </p>
            <a v-if="(index === descriptions.length - 1) && shouldDisplayContactNumber" :href="`tel:${contactNumber.split(' ').join('')}`">
              {{ contactNumber }}
            </a>
          </span>
        </div>
      </div>
      <div class="loader-container" v-if="loading">
        <div>
          <Spinner :size="64" :loader="true" color />
        </div>
      </div>
      <div v-else>
        <FindCompany v-if="!isEditMode && tenantName !== 'canada'" @input="emittedValues => handleCompanySearch(emittedValues)" />
        <DynamicForm
          isNewOnboardingJourney
          @previous-click="() => handlePreviousClick()"
          @submit="event => handleSubmit(event)"
          v-if="formFields"
          :form="formFields"
          :loading="loading"
          :submitState="{ loading: isSubmitting, loadingPercentage }"
          :submitButtonText="isEditMode ? 'Save and update' : 'Next'"
          enableAgreeText
        />
      </div>
    </div>
  </div>
</template>

<script>
import DynamicForm from '@/components/dynamicForms/DynamicForm.vue'
import FindCompany from './FindCompany.vue'
import Spinner from '@/components/Spinner'
import onboardingConfig from '../config'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import deepLinking from '@/mixins/deepLinking'
import config from '@/config/global'
import { formatEnteredCompanyData, formatEditFormPayload, formatRegistrationPayload } from '../formatPayloads'
import { getFormCategory, getOnboardingSection, getFormStructure, getAllForms } from '@/api/goals'
import { onboardingGetters, CLEAR_GET_STARTED_FORM_DATA } from '@/store/modules/onboarding/routines'
import { prepopulateFields, handleTurnoverLastYearDependencies } from '../helpers'
import { tenantGetters } from '@/store/modules/tenant/routines'
import { toastDefaultOptions } from '../../../config/vue-toast'
import { authGetters } from '@/store/modules/auth/routines'
import { filterDynamicForms } from '@/utils/utils'
import { mapGetters, mapActions } from 'vuex'
import { getUser } from '@/api/user'
import { legacyLoanFormId } from '@/utils/constants'

export default {
  name: 'get-started-form',
  mixins: [amplitudeTracking, deepLinking],
  components: { DynamicForm, FindCompany, Spinner },
  data() {
    return {
      areCompanySearchQuestionsShown: false,
      lastEnteredCompanyName: null,
      dynamicCompanyFields: null,
      findCompanyData: null,
      fundingReasons: null,
      fundingGoals: null,
      isEditMode: false,
      formFields: null,
      sectionId: null,
      loading: null
    }
  },
  props: { loadingPercentage: { required: true, type: Number }, isSubmitting: { required: true, type: Boolean } },
  async mounted() {
    this.loading = true
    if (this.user) this.isEditMode = true
    else this.clearFieldValues()

    await this.getFundingReasonsOptions()
    await this.fetchDynamicFields()

    if (this.tenantName === 'canada' && !this.isEditMode) {
      this.toggleCompanySearchRelatedQuestions()
    }

    if (this.isEditMode) await this.initializeForm()
    this.checkForDeepLinkShift()

    this.loading = false
  },
  computed: {
    ...mapGetters({
      getStartedFormData: onboardingGetters.GET_STARTED_FORM_DATA,
      invitedUserSource: authGetters.INVITED_USER_SOURCE,
      isInvitedUser: authGetters.IS_INVITED_USER,
      stageGoal: onboardingGetters.STAGE_GOAL,
      tenantName: tenantGetters.TENANT_NAME,
      user: authGetters.USER
    }),
    descriptions() {
      return onboardingConfig.getStartedPage.description
    },
    contactNumber() {
      return onboardingConfig.getStartedPage.contactUsOnNumber || config.dictionary.contactNumber
    },
    title() {
      return this.isEditMode ? onboardingConfig.getStartedPage.editTitle : onboardingConfig.getStartedPage.title
    },
    shouldDisplayContactNumber() {
      return config.whitelabel.components.getStartedContactNumberEnabled
    }
  },
  methods: {
    ...mapActions({
      clearGetStartedFormData: CLEAR_GET_STARTED_FORM_DATA
    }),
    clearFieldValues() {
      this.clearGetStartedFormData()
      onboardingConfig.getStartedPage.form.formFields.forEach(field => (field.value = null))
    },
    async initializeForm() {
      const { data: userData } = await getUser()
      const formId = this.user?.goalsCompleted[this.user.goalsCompleted.length - 1]?.formId || userData.goalsCompleted[userData.goalsCompleted.length - 1]?.formId
      if (!formId && formId !== legacyLoanFormId) return
      const { data } = await getFormStructure(userData.companies[0].companyId, formId)
      this.formFields = prepopulateFields(data, this.formFields)
    },
    alertHandler(emailAddress) {
      if (!emailAddress) return

      const env = window.location.href.split('.')[1]
      if (env === 'stage' || env === 'dev' || env === 'demo') return

      const email = emailAddress.toLowerCase()
      if (email.includes('swoopfunding.com') || email.includes('+')) {
        if (window.confirm('Did you mean to use the swoop app DEMO?\n\nClick OK to go to the DEMO site or cancel to ignore.')) {
          window.location.href = 'https://app.demo.swoopfunding.com/'
        }
      }
    },
    handleSubmit(values) {
      if (process.env.NODE_ENV === 'removed') {
        this.alertHandler(values.email)
      }
      let currentForm

      if (values.fundingReason === 'Other') {
        const formName = onboardingConfig.detailsPage.formRedirectOnOtherFundingReason[this.stageGoal || 'notSure']
        currentForm = this.fundingGoals.find(goal => goal.name === formName)
      } else {
        currentForm = this.fundingGoals.find(goal => goal.name === values.fundingReason)
      }

      let enteredCompanyDetails = null
      if (this.areCompanySearchQuestionsShown) {
        enteredCompanyDetails = formatEnteredCompanyData(values, currentForm.formId)
      }

      if (this.isEditMode) {
        this.$emit('form-submit', formatEditFormPayload(values, this.formFields, this.sectionId, currentForm))
      } else {
        this.$emit(
          'form-submit',
          formatRegistrationPayload(
            values,
            this.formFields,
            enteredCompanyDetails,
            this.findCompanyData,
            this.sectionId,
            this.tenantName,
            this.isInvitedUser,
            this.invitedUserSource,
            currentForm
          )
        )
      }
    },
    async fetchDynamicFields() {
      try {
        const { data } = await getOnboardingSection(this.stageGoal)
        this.sectionId = data.formSection.formSectionId
        if (data.formSection.formFields) {
          // handles adding of dynamic fields into this.formFields
          this.dynamicCompanyFields = data.formSection.formFields.filter(field => onboardingConfig.getStartedPage.companySearchRelatedFieldNames.includes(field.name))
          const otherDynamicFields = data.formSection.formFields.filter(field => !onboardingConfig.getStartedPage.companySearchRelatedFieldNames.includes(field.name))
          this.formFields = [...otherDynamicFields, ...this.formFields]
        }
      } catch (error) {
        this.$toasted.show('Something went wrong.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    },
    handlePasswordField() {
      if (window.WHITE_LABEL_STYLES?.subdomain !== 'swoop' && !this.formFields.find(field => field?.name === 'password')) {
        this.formFields.push(onboardingConfig.getStartedPage.passwordQuestion)
      }
    },
    async getFundingReasonsOptions() {
      try {
        if (!this.stageGoal || this.stageGoal === 'notSure') {
          const { data } = await getAllForms()
          this.fundingGoals = data
        } else {
          const { data } = await getFormCategory(this.stageGoal)
          this.fundingGoals = data
        }
        if (!this.fundingGoals) throw new Error('failed to fetch funding goals')

        this.fundingGoals = filterDynamicForms(this.fundingGoals)

        const fundingReasonOptions = this.fundingGoals.map(form => ({
          label: form.displayName,
          value: form.name
        }))

        // only add the 'Other' option if the whitelabel config doesn't have the goal it leads to disabled.
        if (
          !config.whitelabel.journey.disabledGoals.includes(onboardingConfig.detailsPage.formRedirectOnOtherFundingReason[this.stageGoal || 'notSure']) &&
          this.stageGoal !== 'purchaseBusinessOrProperty'
        ) {
          fundingReasonOptions.push(onboardingConfig.getStartedPage.otherFundingReasonOption)
        }

        if (this.isEditMode) {
          this.formFields = [onboardingConfig.getStartedPage.form.formFields.find(field => field.name === 'fundingReason')]
          this.formFields[0].options = fundingReasonOptions.flat()
        } else {
          this.formFields = onboardingConfig.getStartedPage.form.formFields
          const fundingReasonFieldIndex = this.formFields.findIndex(field => field.name === 'fundingReason')
          // adds password field for whitelabels
          this.handlePasswordField()
          this.formFields[fundingReasonFieldIndex].options = fundingReasonOptions.flat()
        }

        this.prepopulateFundingReason(fundingReasonOptions)
      } catch (error) {
        this.$toasted.show('Something went wrong.', { ...toastDefaultOptions, type: 'error', icon: 'error' })
      }
    },
    handleCompanySearch(values) {
      if (values.companyName) this.lastEnteredCompanyName = values.companyName

      if (values.isCantFindCompany !== this.areCompanySearchQuestionsShown) {
        this.toggleCompanySearchRelatedQuestions()
      }
      this.findCompanyData = values
    },
    toggleCompanySearchRelatedQuestions() {
      this.areCompanySearchQuestionsShown = !this.areCompanySearchQuestionsShown
      if (this.areCompanySearchQuestionsShown) {
        const companyNameQuestions = onboardingConfig.getStartedPage.form.companyNameQuestions
        companyNameQuestions[0].value = this.lastEnteredCompanyName
        const newFields = [].concat([...companyNameQuestions, ...onboardingConfig.getStartedPage.staticQuestions], this.dynamicCompanyFields)
        this.formFields = [].concat(newFields, this.formFields)
        this.handlePasswordField()
      } else {
        this.formFields = this.formFields.filter(field => !onboardingConfig.getStartedPage.companySearchRelatedFieldNames.includes(field.name))
      }
    },
    prepopulateFundingReason(options) {
      const value = this.$route.query.fundingReason || this.$route.params?.fundingReason
      if (value) {
        const fundingReasonField = this.formFields.find(field => field.name === 'fundingReason')
        if (options.find(option => option.value === value)) fundingReasonField.value = value
      }
    }
  },
  watch: {
    areCompanySearchQuestionsShown(newValue) {
      const turnoverLastYear = this.formFields.find(field => field?.name === 'turnoverLastYear')
      if (turnoverLastYear) handleTurnoverLastYearDependencies(turnoverLastYear, newValue)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.wrapper {
  width: 100%;
}

.onboarding-header {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px;
  span {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $color-primary;
    text-decoration: none;
  }
  button {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 80px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--color-secondary-100);
    border-radius: 10px;
    background-color: transparent;
    color: $color-general-headers;
    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--color-primary-400);
    }
  }
}

.text-container {
  padding: 0 50px;
  margin-bottom: 48px;

  h3 {
    font-size: 28px;
  }
  .description-container {
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    .inline-para {
      display: inline;
      word-wrap: break-word;
    }
    a {
      font-size: 16px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 1600px;
  justify-content: center;
  .loader-container {
    display: flex;
    justify-self: center;
    align-self: center;
  }
}
@media only screen and (max-width: 600px) {
  .text-container {
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .content {
    padding: 8px;
  }
}
@media only screen and (max-width: 830px) {
  .text-container {
    margin-top: 24px;
    padding: 0 12px;
    margin-bottom: 24px;
  }
}
</style>
