<template>
  <modal :clickToClose="true" name="loginModal" classes="v--modal" height="700" width="700" class="modal">
    <section v-if="modalStart">
      <signIn isModal />
    </section>
    <IllionIFrame v-else mode="modal" data-testid="modal-iframe" origin="popup" @submit_all="setModalFlag" />
    <button class="close-modal" @click="_closeModal">X</button>
  </modal>
</template>

<script>
import IllionIFrame from '@/features/illion/IllionIFrame'
import { amplitudeEvents } from '@/utils/constants'
import signIn from '@/features/signIn/signIn.vue'

export default {
  name: 'loginModal',
  props: {
    closeModal: {
      required: true
    }
  },
  components: {
    IllionIFrame,
    signIn
  },
  mounted() {
    this.$modal.show('loginModal')
  },
  data() {
    return {
      amplitudeEvents,
      modalStart: true
    }
  },
  methods: {
    _closeModal() {
      this.$modal.hide('loginModal')
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/swoop/_variables';

.close-modal {
  text-decoration: none;
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 20px;
  line-height: 0;
  z-index: 10;
}
</style>
